<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <section :id="block.uniqueId" :class="`${block.sectionBlock}`">
    <div class="text-dynamic mx-auto max-w-6xl px-4 py-6 md:px-6 md:py-10">
      <div class="mb-6 flex flex-wrap space-y-4" :class="flexAlign">
        <PageBuilder
          :blocks="block.children"
          :globals="globals"
          :page-type="pageType"
        />
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
const props = defineProps({
  block: {
    type: Object,
    default: () => {},
  },
  globals: {
    type: Object,
    default: () => {},
  },
  pageType: {
    type: String,
    default: 'page',
  },
});

type TextAlign = 'center' | 'left' | 'right';

const flexAlignClasses: Record<TextAlign, string> = {
  center: 'justify-center',
  left: 'justify-start',
  right: 'justify-end',
};

const flexAlign = computed(
  () => flexAlignClasses[props?.block?.textAlign as TextAlign] || ''
);
</script>
